<template>
  <div class="grid">
    <div class="col-12">
      <div class="font-medium text-3xl text-900 mb-3">
        <span class="text-primary text-5xl">/</span> {{ $route.meta.title }}
      </div>

      <div class="card">

        <DataTable dataKey="id" :value="records" :row-hover="true" responsiveLayout="stack" breakpoint="640px">

          <template #empty> Nenhum registro encontrado. </template>

          <template #loading> Carregando registros. Aguarde ... </template>

          <Column field="id" header="#"></Column>

          <Column field="updatedAt" header="Data de referência" :sortable="true">
            <template #body="{ data }">
              {{ $filters.dateTime(data.updatedAt) }}
            </template>
          </Column>

          <Column field="items.length" header="Histórias"></Column>

          <Column field="status" header="Situação"></Column>

          <Column>
            <template #body>
              <Button icon="pi pi-ellipsis-v" class="p-button-rounded p-button-text"
                v-tooltip.top="'Histórias exportadas'" />
              <Button icon="pi pi-download" class="p-button-rounded p-button-text" v-tooltip.top="'Download'" />
              <Button icon="pi pi-send" class="p-button-rounded p-button-text" v-tooltip.top="'Enviar'" />
            </template>
          </Column>

        </DataTable>

        <Paginator :rows="perPage" :totalRecords="totalItems" @page="onPage"
          template="PrevPageLink PageLinks NextPageLink CurrentPageReport"
          currentPageReportTemplate="Página {currentPage} de {totalPages}">
        </Paginator>

        <Dialog header="Log da transação" v-model:visible="displayLog" :modal="true">
          <json-viewer :value="log" :expand-depth=5 copyable boxed sort>
          </json-viewer>

          <template #footer>
            <Button label="Fechar" icon="pi pi-times" @click="closeLog" class="p-button-text" />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import JsonViewer from 'vue-json-viewer';
import fileDownload from 'js-file-download';
import PageableList from '../../components/abstract/PageableList.vue';
import CrudService from '@/services/crud'
import { getClient } from '../../services/http';

export default {
  extends: PageableList,
  components: {
    JsonViewer
  },
  data() {
    return {
      service: new CrudService(`/admin/exports`),
      displayLog: false,
      log: null,
    }
  },
  methods: {
    openLog(item) {
      this.log = item.data;
      this.displayLog = true;
    },
    closeLog() {
      this.log = null;
      this.displayLog = false;
    },
    async download() {
      const { data } = await getClient().get(`/admin/relatorios/${this.$route.meta.type}/csv`, {
        params: {
          filter: this.filter,
          dateStart: this.dateStart,
          dateFinish: this.dateFinish
        }
      })
      fileDownload(data, `${this.type}.csv`)
    }
  }
}
</script>

<style scoped lang="scss">

</style>
